<template>
  <div class="flexdc flex1" style="height: 100%">
    <div class="operationControl">
      <div class="searchbox">
        <div title="试卷名称" class="searchboxItem ci-full">
          <span class="itemLabel">试卷名称:</span>
          <el-input
            v-model="searchData.paperName"
            size="small"
            placeholder="请输入试卷名称"
            clearable
          />
        </div>
        <div title="培训类型" class="searchboxItem">
          <span class="itemLabel">培训类型:</span>
          <el-cascader
            v-model="searchData.trainTypeId"
            :options="TrainTypeList"
            filterable
            clearable
            :show-all-levels="false"
            :props="{
              emitPath: false,
              value: 'id',
              label: 'label',
              children: 'children',
              checkStrictly: true,
            }"
            size="small"
          ></el-cascader>
        </div>
        <div title="试卷来源" class="searchboxItem ci-full">
          <span class="itemLabel">试卷来源:</span>
          <el-select
            size="small"
            v-model="searchData.paperOrgin"
            clearable
            placeholder="请选择试卷来源"
          >
            <el-option
              v-for="item in paperOrginList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="df" style="padding-right: 40px">
          <el-button type="primary" class="bgc-bv" round @click="getData()"
            >查询</el-button
          >
          <el-button
            type="primary"
            class="bgc-bv"
            round
            @click="handleAddPaper()"
            >新增试卷</el-button
          >
        </div>
      </div>
    </div>

    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :cell-style="cellStyle"
          :header-cell-style="tableHeader"
        >
          <el-table-column
            label="序号"
            align="center"
            fixed
            type="index"
            :index="indexMethod"
          />
          <el-table-column
            label="试卷名称"
            align="center"
            width="185"
            prop="paperName"
          />
          <el-table-column
            label="培训类型"
            align="center"
            prop="trainTypeNamePath"
            min-width="180"
          />
          <el-table-column
            label="试题数量"
            align="center"
            prop="questionNum"
            min-width="160"
          />
          <el-table-column
            label="及格/满分"
            align="center"
            min-width="120"
            prop="score"
          />
          <el-table-column
            label="试卷来源"
            align="center"
            min-width="120"
            prop="paperOrgin"
            class="resd"
          >
            <template slot-scope="scope">
              {{ $setDictionary("PAPERORGIN", scope.row.paperOrgin) }}
            </template>
          </el-table-column>
          <el-table-column
            label="引用次数"
            align="center"
            width="120"
            prop="usageNum"
          >
            <template slot-scope="scope">
              {{ scope.row.usageNum }}
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            align="center"
            prop="createTime"
            width="160"
          >
            <template slot-scope="scope">
              {{ scope.row.createTime }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="left" width="200" fixed="right">
            <template slot-scope="scope">
              <el-button
                type="text"
                style="padding: 0px 5px"
                size="mini"
                @click="handleLook(scope.row.paperId,scope.row.paperOrgin)"
                >查看</el-button
              >
              <el-button
                type="text"
                style="padding: 0px 5px"
                size="mini"
                :disabled="scope.row.paperOrgin == '20'"
                @click="handleDeletePaper(scope.row.paperId)"
                >删除</el-button
              >
              <el-button
                type="text"
                style="padding: 0px 5px"
                size="mini"
                 :disabled="scope.row.paperOrgin == '20'"
                @click="handleCopyPaper(scope.row.paperId)"
                >复制试卷</el-button
              >
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue"; //引入表格无数据提示
import PageNum from "@/components/PageNum.vue"; //引入分页-组件
import List from "@/mixins/List"; //引入列表 - 混入
import { resetKeepAlive } from "@/utils/common"; //缓存页面
import { mapGetters } from "vuex";
export default {
  name: "enterpriseInternalTrainingOrder",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      //检索条件
      TrainTypeList: [], //培训类型数据
      searchData: {
        paperName: "", //试卷名称
        trainTypeId: "", //培训类型Id
        paperOrgin: "", // 试卷来源
      },
      paperOrginList:[], //试卷来源数据
    };
  },
  created() {
    this.getTrainTypeArrData();
    this.getCode();
  },
  mounted() {},
  computed: {},
  methods: {
      //获取码值
    getCode() {
      let list = this.$setDictionary("PAPERORGIN", "list");
      for (const key in list) {
        if(key != '30'){
          this.paperOrginList.push({
          value: key,
          label: list[key],
        });
        }
        
      }
    },
    //获取培训类型数据
    getTrainTypeArrData() {
      this.$post("/sys/category/train-type/enterprise", {}).then((ret) => {
        this.TrainTypeList = ret.data;
      });
    },
    //获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchData.paperName) {
        params.paperName = this.searchData.paperName;
      }
      if (this.searchData.trainTypeId) {
        params.trainTypeId = this.searchData.trainTypeId;
      }
      if (this.searchData.paperOrgin) {
        params.paperOrgin = this.searchData.paperOrgin;
      }
      this.doFetch({
        url: "/biz/et/paper/pageList",
        params,
        pageNum,
      });
    },

    //查看
    handleLook(paperId,paperOrgin) {
      this.$router.push({
        path: "/web/CustomPrograms/testPaperEdit",
        query: {
          paperId,
          stu: "edit",
          paperOrgin
        },
      });
    },
    //删除
    handleDeletePaper(paperId) {
 this.doDel({
        title: "删除",
        url: "/biz/paper/deletePaper",
        msg:
          "你确定要删除该试卷吗?课件/课程关联了该试卷，课件/课程中的试卷也会被同时删除？",
        ps: {
          type: "post",
          data: { paperId }
        }
      });
    },
    //复制试卷
      handleCopyPaper(paperId) {
      this.$post("/biz/paper/copyPaper", {paperId})
        .then(ret => {
          if (ret.status == "0") {
            this.$message({
              type: "success",
              message: "修改成功"
            });
            this.getData(-1);
          }
        })
        .catch(err => {
          return;
        });
    },
    //新增试卷
    handleAddPaper() {
 this.$router.push({
          path: "/web/CustomPrograms/testPaperAdd"
        });
    },
    //获取高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35+3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
    beforeRouteLeave: resetKeepAlive,
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh || val.query.refresh == "true") {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.enterpriseInternalTrainingOrder {
  .itemLabel {
    min-width: 6rem;
  }
}
.amountSummary {
  padding-left: 12px;
  span {
    color: red;
  }
}
.btnBox {
  display: flex;
  justify-content: center;
}
</style>
